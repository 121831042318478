<template>
  <div row="">
    <div id="mfc-landing-map">
      <yandex-map :settings="settings" :coords="coords" @map-was-initialized="init" :zoom="zoom" :controls="['zoomControl', 'geolocationControl']">
      </yandex-map>
    </div>
  </div>
</template>

<script>
  import { yandexMap } from 'vue-yandex-maps'
  import Vue2Filters from 'vue2-filters'
  import _map from 'lodash/map'
  import filter from 'lodash/filter'
  import find from 'lodash/find'

  export default {
    components: { yandexMap },
    mixins: [Vue2Filters.mixin],
    props: ["mfcs", "xCoordinate", "yCoordinate", "valueFilter", "zoom"],
    data() {
      return {
        fff: '',
        settings: {
          apiKey: 'f9a1fa97-8b87-476a-a41d-03029c835f9b',
          lang: 'ru_RU',
          version: '2.1'
        },
        collection: this.mfcs,
        filter: {
          'true': 'Проводят онлайн-консультации',
          'false': 'Принимают заявки на онлайн-консультации'
        }
      }
    },
    computed: {
      coords() {
        return [this.xCoordinate, this.yCoordinate]
      }
    },
    methods: {
      submit (formClassName) {
        if (formClassName && formClassName.value) {
          document.location.href = this.$routes.root_path({mfc: {active: true}, anchor: "mfcs"})
        } else {
          document.location.href =  this.$routes.root_path({anchor: "mfcs"})
        }
      },
      init(map) {

        function CustomSearchProvider(points) {
            this.points = points;
        }

        CustomSearchProvider.prototype.geocode = function (request, options) {
            var deferred = new ymaps.vow.defer(),
                geoObjects = new ymaps.GeoObjectCollection(),
                offset = options.skip || 0,
                limit = options.results || 20;

            var points = [];
            for (var i = 0, l = this.points.length; i < l; i++) {
                var point = this.points[i];
                if (point.text.toLowerCase().indexOf(request.toLowerCase()) != -1) {
                    points.push(point);
                }
            }
            points = points.splice(offset, limit);
            for (var i = 0, l = points.length; i < l; i++) {
                var point = points[i],
                    coords = point.coords,
                            text = point.text;

                geoObjects.add(new ymaps.Placemark(coords, {
                    name: text,
                    balloonContentBody: '<p>' + text + '</p>',
                    boundedBy: [coords, coords]
                }));
            }

            deferred.resolve({
                geoObjects: geoObjects,
                metaData: {
                    geocoder: {
                        request: request,
                        found: geoObjects.getLength(),
                        results: limit,
                        skip: offset
                    }
                }
            });

            return deferred.promise();
        };

        let mfcPoints = _map(this.mfcs, (mfc) => {
          return {
            coords: [mfc.lat, mfc.lon],
            disbled: mfc.online,
            text: mfc.name
          }
        });

        let mfcObjects = _map(this.mfcs, (mfc) => {
          return {
            "type": "Feature",
            "disbled": mfc.online,
            "id": mfc.id,
            "text": mfc.name,
            "geometry": {
              "type": "Point",
              "coordinates": [mfc.lat, mfc.lon]
            },
            "properties": {
              "balloonContent": {online: mfc.online},
              "balloonContentBody": "<p>Адрес: "+mfc.name+"</p>"+
                                     (mfc.phone ? '<p>Телефон: '+mfc.phone+' </p>' : "")+""+
                                     (mfc.email ? '<p>Почта: <a target=\"_blank\" href=\"mailto:'+mfc.email+'\">'+mfc.email+'</a></p>' : "")+""+
                                     (mfc.url ? '<p>Сайт:<a target="_blank" href='+mfc.url+'> '+mfc.url+'</a></p>' : ""),
              "clusterCaption": mfc.name,
              "hintContent": mfc.name
            }
          }
        });

        var listBoxItems = ["Принимают заявки на онлайн-консультации", "Проводят онлайн-консультации"]
            .map((title) => {
                return new ymaps.control.ListBoxItem({
                    data: {
                        content: title
                    },
                    state: {
                        selected: ((title == "Принимают заявки на онлайн-консультации" && !this.valueFilter) || ((title != "Принимают заявки на онлайн-консультации") && this.valueFilter)) ? true : false,
                        enabled: ((title == "Принимают заявки на онлайн-консультации" && !this.valueFilter) || ((title != "Принимают заявки на онлайн-консультации") && this.valueFilter)) ? false : true,
                    },

                })
            }),
        reducer = function (filters, filter) {
            filters[filter.data.get('content')] = filter.isSelected();
            return filters;
        },

        listBoxControl = new ymaps.control.ListBox({
            data: {
                content: this.filter[Boolean(this.valueFilter)] || 'Фильтр',
                title: this.filter[Boolean(this.valueFilter)] || 'Фильтр'
            },
            items: listBoxItems,
            state: {
                expanded: false,
                filters: listBoxItems.reduce(reducer, {})
            }
        });

        listBoxControl.events.add(['select', 'deselect'], (e) => {
            var listBoxItem = e.get('target');
            if (listBoxItem.isSelected()) {
              listBoxItems.forEach((item) => {
                if (item.data.get('content') != listBoxItem.data.get('content')) {
                  if (item.isSelected()) {
                    item.deselect()
                  }
                }
              })
            }
            if (listBoxItem.data.get('content') == 'Принимают заявки на онлайн-консультации') {
              if (listBoxItem.isSelected()) {
                document.location.href =  this.$routes.root_path({anchor: "mfcs"})
              }
            } else {
              if (listBoxItem.isSelected()) {
                document.location.href = this.$routes.root_path({mfc: {active: true}, anchor: "mfcs"})
              } else {
                document.location.href =  this.$routes.root_path({anchor: "mfcs"})
              }
            }
        });

        var filterMonitor = new ymaps.Monitor(listBoxControl.state);
        filterMonitor.add('filters', function (filters) {
            // Применим фильтр.
            objectManager.setFilter(getFilterFunction(filters));
        });

        function getFilterFunction(categories) {
            return function (obj) {
                var content = obj.properties.balloonContent;
                if (content.online) {
                  return categories["Принимают заявки на онлайн-консультации"] || categories["Проводят онлайн-консультации"]
                } else {
                  return categories["Принимают заявки на онлайн-консультации"]
                }
            }
        }

        let searchControl = new ymaps.control.SearchControl({
          options: {
              provider: new CustomSearchProvider(mfcPoints),
              noPlacemark: true,
              resultsPerPage: 5
          }
        });
        let objectManager = new ymaps.ObjectManager({
            clusterize: true,
            gridSize: 64,
            clusterDisableClickZoom: true
        });
        map.controls.add(searchControl);
        map.controls.add(listBoxControl);
        objectManager.objects.options.set('preset', 'islands#blueDotIcon');
        objectManager.clusters.options.set('preset', 'islands#blueClusterIcons');
        map.geoObjects.removeAll();
        map.geoObjects.add(objectManager);

        objectManager.add({
          "type": "FeatureCollection",
          "features": mfcObjects
        });
      }
    }
  }
</script>

<style lang="scss">
  #mfc-landing-map .ymap-container {
    height: 716px;
    width: 1240px;
    // margin-right: 16px;
    border-radius: 5px;
    border: 1px solid rgb(218, 218, 218);
    overflow: hidden;
  }
</style>
